<template>
    <page :loading="loading">
        <div class="head">
			<div class="block">
				<div class="select">
					<el-select v-model="table.search.status" placeholder="广告展示状态">
						<el-option label="全部" value="" />
						<el-option label="已完成" :value="1" />
						<el-option label="未完成" :value="0" />
					</el-select>
				</div>
				<div class="date-picker margin-left">
					<el-date-picker value-format="yyyy-MM-dd" v-model="datePickerValue" @change="datePickerChange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</div>
				<div class="search margin-left">
					<el-button type="primary" @click="LoadDatas(table.search._page,table.search._size)">搜 索</el-button>
				</div>
			</div>
        </div>

        <page-table :datas="table.data" :page="table.search._page" :size="table.search._size" :total="table.search._count" @change="LoadDatas">
            <el-table-column prop="id"                             label="id" align="center" fixed="left"  width="120" />
            <el-table-column prop='user.username'                       label='用户名' align='center' />
			<el-table-column prop='user.phone_model'                    label='手机型号' align='center' />
			<el-table-column :formatter="typeFormat"   		       label='类型' align='center' prop='type'/>
            <el-table-column :formatter="statusFormat"   		   label='状态' align='center' prop='status'/>
            <el-table-column prop='created_at'                     label='展示时间' align='center' />
        </page-table>

    </page>
</template>
<script>
export default {
    data(){
        return {
            loading:false,
            table:{
                data:[],
                form:{},
                search:{
                    _page:1,
                    _size:20,
                    _count:0,
					status: '',
                }
            },
			statusList:['未完成','已完成'],
			typeList:['banner','插屏','激励视频','原生信息流'],
			datePickerValue: '',
        }
    },
    mounted() {
        this.LoadDatas();
    },
    methods: {
		//类型格式化
		typeFormat(row) {
		  return this.typeList[row.type];
		},
		//状态格式化
		statusFormat(row) {
		  return this.statusList[row.status];
		},
		datePickerChange(value){
			if(value){
				this.table.search.created_atstart = value[0] + ' 00:00:00';
				this.table.search.created_atend   = value[1] + ' 23:59:59';
			}else{
				this.table.search.created_atstart = '';
				this.table.search.created_atend   = '';
			}
		},
        /**
         * 获取数据列表
         * @param page 分页-当前页
         * @param size 分页-每页数量
         * **/
        LoadDatas(page,size){
            page && (this.table.search._page = page),size && (this.table.search._size = size),this.loading = true;
            this.$get('app-advert-record',this.table.search).then(res=>
            {
                this.table.data = res.data , this.table.search._count = res.extra.pageInfo.count , this.loading = false;
            }).catch(err=>
            {
                this.$notify.error({message: err || '网络请求错误'}),this.loading = false;
            });
        },

    },
}
</script>

<style scoped>
	.block {
		margin-top: 20px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.margin-left {
		margin-left: 20px;
	}
</style>